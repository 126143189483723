const Homepage = ({accessKey}) => {

    const handleBuyNow = () => {
        window.open('https://buy.stripe.com/6oE9Dn1qt2wZ20U4gh', '_blank');
      };

    return (
        <div className='homepage d-flex'>
            <div className='text-center w-80'>


            {accessKey === 'paid' ? 
                <>
                <br />
                <br />
                <h2>Paid Plan 🙌</h2>
                </>
                : null}

                <br />
                {accessKey === 'demo' ? 
                <>
                <h2>💰 Pay Once Use Forever</h2>
                {/* <br /> */}
                <h2>$30</h2>

                <button className='buy-now-hp mt-20' onClick={handleBuyNow}>Buy Now</button>

                <br />
                <br />
                </> : null}

                <h1>Welcome</h1>
                <h1>To 📓 Note Workspace</h1>
                <h2>Your Notetaking Dashboard <span className='desktop-only'>📊</span></h2>
                <br />
                <h3>A place to study, take notes, and organize your tasks 💻 📝 ✅ 🔖 🔗 📹</h3>

                <br />
                <h4>✅ Create To Do Lists </h4>
                <h4>🔗 Save Links </h4>
                <h4>📝 Take Notes</h4>
                <h4>📹 Study on YouTube</h4>

                <br />
                <br />
                <h2>No Login Required</h2>
                {/* <Link to={`/faq`}><h3 className="color-blue pointer" style={{ textDecoration: "underline" }} >Why?</h3></Link> */}


                <h2>🎉 Happy Studying!</h2>

                <br />
                <br />

                <h2>Also Check out</h2>
                <h2><a href="https://www.digitalnomadtube.com" target="_black" style={{ color: "#1E90FF" }}>🏝️ Digitalnomadtube.com</a></h2>
                <h2><a href="https://www.ramenlinks.com" target="_black" style={{ color: "#1E90FF" }}>🍜 Ramenlinks.com</a></h2>
                {/* <h2><a href="https://www.gonetmr.com" target="_black" style={{ color: "#1E90FF" }}>⏱️☑️ Gonetmr.com</a></h2>
                <h2><a href="https://www.bookmarker.com" target="_black" style={{ color: "#1E90FF" }}>🔗 Bookmarker.com</a></h2> */}
                <br />
                <br />
                <br />

                <h2>(Please Read the ❗️Disclaimer <span className='desktop-only'>➡️</span> <span className='mobile-tablet-only'>👇</span>)</h2>
            </div>


            <div className='text-center w-20 border-l disclaimer'>


            <br />
            <br />
                <h3>❗️Disclaimer</h3>
                <br />
                <br />

                {/* <h2>How is my data stored?</h2> */}


                <h4>💾 Data is saved to your device </h4>
                    <h4>(your browser's local storage)</h4>

                <br />
                <br />

                <h4>🗄️ Not A Server </h4>

                <br />
                <br />

                <h4>This means that if you...</h4>
                <h5>❌ Delete your browsing history</h5>
                <h5>❌ Clear your cache</h5>
                <h5>❌ or delete cookies</h5>
                <h5>You'll lose all your notes❗️</h5>

                <br />
                <br />

                <h4>But you can... 😃</h4>

                <br />
                <br />

                <h4>💾 Download your data</h4>
                <h5>⬅️ in the sidebar</h5>
                <h5>📄 As a file</h5>
                <h5>As a backup</h5>
                <h5>Think of it like saving a Microsoft Word file</h5>
                <h5>Then re-upload</h5>
                <h5>To any device</h5>

                <br />
                <br />

                <h4>Pros</h4>
                <h5>✅ No login</h5>
                <h5>✅ No Server</h5>
                <h5>✅ You own all your data</h5>
                <h5>✅ Each device is a blank slate</h5>
                <h5>✅ Create multiple workspaces with multiple files</h5>

                <br />
                <br />

                <h4>Cons</h4>
                <h5>❌ Need to save often</h5>
                <h5>❌ Difficult to use between devices</h5>
                <h5>❌ Data will dissapear if you clear your browser or use in incognito mode</h5>


                <br />
                <br />
                <br />
                <br />

            </div>



        </div>
    )
}

export default Homepage;