import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './announcementbar.css'

function AnnouncementBar(){
    const [storageSize, setStorageSize] = useState(0); // To store the size of the local storage in bytes

    // Load content from localStorage when the component mounts
    useEffect(() => {
        const savedContent = localStorage.getItem('NoteWorkspace');
        if (savedContent) {
            updateStorageSize(savedContent);
        }
    }, []);

        // Function to calculate and set the storage size in bytes and kilobytes
        const updateStorageSize = (content) => {
            const sizeInBytes = new Blob([content]).size; // Calculate size in bytes
            setStorageSize(sizeInBytes); // Set size in state
        };

        function downloadLocalStorage() {
            const data = JSON.parse(localStorage.getItem('NoteWorkspace')); // Parse the stored JSON string back to an object
            const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }); // Now stringify as JSON with spacing
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'NoteWorkspace.json';
            link.click();
          }
        

    return(
        <>
        <div className="announcement-bar">

        <Link to={`/faq`}  className='info mobile-tablet-only d-flex align-center col-gap-5' >
            <div className="d-flex align-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-info"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
            </div>
            <div>Info</div>
        </Link>

            <div className="plan">
                <div className="space-used">
                    Space Used:  
                    {storageSize < 1024 * 1024
                        ? ` ${(storageSize / 1024).toFixed(2)} KB `
                        : `${(storageSize / (1024 * 1024)).toFixed(2)} MB`}
                    {/* / 5MB */} / 5000 KB
                </div>
            </div>

            {/* <div className="buy">Upgrade to Premium To Save Your Data</div> */}

            <div className="warning d-flex align-center">
                ❗️Warning - Clearing Your Browser History, Cookies, Or Cache Will Delete All Data
                <Link to={`/faq`} className='color-white'><span className="underline mx-5 pointer">Why?</span></Link>
                <div className='d-flex align-center'><div style={{fontSize: "8px", marginRight: '2px'}}>❌</div> <div style={{marginTop: '2px'}}>Private Browsing</div></div>
            </div>


                <div className="warning d-flex align-center">                
                
                <div>Backup your data by</div>
                
                <div className='underline d-flex align-center ml-5 mr-5' style={{color: "#eee", cursor: "pointer"}} onClick={downloadLocalStorage}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/><polyline points="7 10 12 15 17 10"/><line x1="12" x2="12" y1="15" y2="3"/></svg>
                    Downloading a copy.
                </div> 

                <div>Save often.</div>
                    
                {/* <div className='underline ml-5 d-flex align-center' style={{color: "#eee", cursor: "pointer"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-info"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
                    More info
                </div>             */}
            </div>

        </div>
        </>
    )
}

export default AnnouncementBar;