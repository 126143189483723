import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

const SidebarPage = ({
  folderOpen,
  folderId,
  folder,
  setCurrentNote,
  editingPageId,
  editingFolderForPageId,
  newPageNameInput,
  setNewPageNameInput,
  handleRenamePage,
  togglePopupPage,
  openMenu,
  setOpenMenu,
  closeSidebar,

  popupRef,
  setEditingPageId,
  setEditingFolderForPageId,
  togglePopup,
  deletePage,
  addPage,
  updateFolders,
  folders,
}) => {

  // Custom hook to handle sortable items (pages)
  const PageItem = ({ pageId, page }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: pageId });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} className="folder-pages-inner item d-flex justify-between">
        {/* Drag Handle */}
        <span {...listeners} className="drag-handle">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical">
            <circle cx="9" cy="12" r="1" />
            <circle cx="9" cy="5" r="1" />
            <circle cx="9" cy="19" r="1" />
            <circle cx="15" cy="12" r="1" />
            <circle cx="15" cy="5" r="1" />
            <circle cx="15" cy="19" r="1" />
          </svg>
        </span>

        {editingPageId === pageId && editingFolderForPageId === folderId ? (
          <input
            type="text"
            value={newPageNameInput}
            onChange={(e) => setNewPageNameInput(e.target.value)}
            onBlur={() => handleRenamePage(folderId, pageId)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleRenamePage(folderId, pageId);
            }}
            autoFocus
          />
        ) : (
          <>
            <div className='folder-pages-inner-wrapper'>
              <Link to={`/${folderId}/${pageId}`} {...attributes} onClick={() => {setCurrentNote(0);closeSidebar();}}>
                📓 {page.name}
              </Link>
              {/* Ellipsis icon with tooltip and popup menu */}
              <div className='folder-menu folder-menutooltip tooltip' onClick={() => togglePopupPage(pageId)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ellipsis">
                  <circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" />
                  </svg>
                  <span className="tooltip-text">Options</span>
              </div>
            </div>

            {openMenu === pageId && (
            <>
            <div ref={popupRef} className={`popup-menu show-popup ${openMenu.folderId === folder.id ? 'show-popup' : ''}`}>
                <button onClick={() => {
                setEditingPageId(pageId);
                setEditingFolderForPageId(folderId);
                setNewPageNameInput(page.name);
                togglePopup(pageId);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg>
                Rename
                </button>
                <button onClick={() => deletePage(folderId, pageId)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                Delete</button>
            </div>
            </>
            )}
          </>
        )}
      </div>
    );
  };

  const handlePageDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const pagesArray = Object.entries(folder.pages);
      const oldIndex = pagesArray.findIndex(([id]) => id === active.id);
      const newIndex = pagesArray.findIndex(([id]) => id === over.id);

      const newOrderArray = arrayMove(pagesArray, oldIndex, newIndex);
      const newOrder = Object.fromEntries(newOrderArray);

      const updatedFolders = {
        ...folders,
        [folderId]: {
          ...folder,
          pages: newOrder,
        },
      };

      // Update folders and save page order to Local Storage
      updateFolders(updatedFolders);
      const savedWorkspace = JSON.parse(localStorage.getItem('NoteWorkspace')) || {};
      savedWorkspace[folderId].pages = newOrder;
      localStorage.setItem('NoteWorkspace', JSON.stringify(savedWorkspace));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setOpenMenu(null);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

  return (
    <>
      {folderOpen[folderId] && (
        <DndContext onDragEnd={handlePageDragEnd} collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}>
          <SortableContext items={Object.keys(folder.pages)} strategy={verticalListSortingStrategy}>
            <div className="folder-pages pl-4">
              {Object.entries(folder.pages).map(([pageId, page]) => (
                <PageItem key={pageId} pageId={pageId} page={page} />
              ))}
              {folderId !== 'home' && (
                <div className="mt-5 mb-5 pr-20">
                  <button className='w-100 text-left add-page' onClick={() => addPage(folderId)}>+ Add Page</button>
                </div>
              )}
            </div>
          </SortableContext>
        </DndContext>
      )}
    </>
  );
};

export default SidebarPage;
