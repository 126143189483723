import './search.css';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

function Search({onAddVideo}) {
    const [activeSearch, setActiveSearch] = useState('search-google'); // Default active input
    const [inputValue, setInputValue] = useState('');
    const { folderId, pageId } = useParams();

    const handleSearchClick = (searchType) => {
        setActiveSearch(searchType);
    };

    // Function to extract video ID from a YouTube URL
    const extractVideoId = (url) => {
        const urlPatterns = [
            /youtube\.com\/watch\?v=([^\&\?\/]+)/,     // Matches URLs like 'youtube.com/watch?v=VIDEO_ID'
            /youtu\.be\/([^\&\?\/]+)/                 // Matches URLs like 'youtu.be/VIDEO_ID'
        ];
        
        for (const pattern of urlPatterns) {
            const match = url.match(pattern);
            if (match) {
                return match[1];
            }
        }
        
        return null;
    };

    const handleAddVideo = () => {
        const videoId = extractVideoId(inputValue);
        
        if (videoId) {
            // Call the function passed down from parent to add video ID to local storage
            onAddVideo(folderId, pageId, videoId);
            setInputValue(''); // Clear input after adding
        } else {
            alert("Invalid YouTube URL or Video ID");
        }
    };

    const searchYouTube = () => {
        if (inputValue.trim()) {
            const searchUrl = `https://www.youtube.com/results?search_query=${encodeURIComponent(inputValue)}`;
            window.open(searchUrl, '_blank');
            setInputValue('');
        }
    };

    const searchGoogle = () => {
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(inputValue)}`;
        window.open(searchUrl, '_blank');
        setInputValue('');
    };
    
    const searchChatGPT = () => {
        const searchUrl = `https://chat.openai.com/?q=${encodeURIComponent(inputValue)}`;
        window.open(searchUrl, '_blank');
        setInputValue('');
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (activeSearch === 'search-youtube') {
                searchYouTube();
            } else if (activeSearch === 'search-google') {
                searchGoogle();
            } else if (activeSearch === 'search-chatgpt') {
                searchChatGPT();
            }
        }
    };    

    return (
        <div className="search card search-only">
            <div className="d-flex justify-between align-center mb-5">
                {/* <div className='d-flex align-center col-gap-10'>
                    <div 
                        className={`add-a-video ${activeSearch === 'add-a-video' ? 'active' : ''}`}
                        onClick={() => handleSearchClick('add-a-video')}
                    >
                        <div className='d-flex col-gap-5 align-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-youtube"><path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"/><path d="m10 15 5-3-5-3z"/></svg>
                            <h5>Add A Video</h5>
                        </div>
                    </div>
                    <a href="https://www.youtube.com" target="_blank" className='youtube-link fs-12 d-flex align-center col-gap-5'>
                        <div>YouTube</div>
                        <div className='d-flex align-center'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></div>
                    </a>
                </div> */}

                <div className='d-flex align-center col-gap-5'>
                    <h5 style={{margin:"0px"}}>🔍 Search: </h5>
                    <div className='d-flex align-baseline'>
                        <div className='d-flex col-gap-5 fs-12'> 
                            <div 
                                className={`search-google ${activeSearch === 'search-google' ? 'active' : ''}`}
                                onClick={() => handleSearchClick('search-google')}
                            >
                                Google
                            </div>
                            | 
                            <div 
                                className={`search-chatgpt ${activeSearch === 'search-chatgpt' ? 'active' : ''}`}
                                onClick={() => handleSearchClick('search-chatgpt')}
                            >
                                ChatGPT
                            </div>
                            | 
                            <div 
                                className={`search-youtube ${activeSearch === 'search-youtube' ? 'active' : ''}`}
                                onClick={() => handleSearchClick('search-youtube')}
                            >
                                YouTube
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Conditionally render input fields based on activeSearch */}
            {activeSearch === 'add-a-video' && (
                <div className='d-flex add-a-video-input'>
                <input 
                    className='w-100' 
                    placeholder='Paste a YouTube URL or Video ID'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                    <button onClick={handleAddVideo} style={{width: '77px'}}>Add</button>
                </div>
            )}

            {activeSearch === 'search-google' && (
                <div className='d-flex search-google-input'>
                    <input 
                        className='w-100' 
                        placeholder='Search Google' 
                        value={inputValue} 
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown} // Trigger search on Enter
                    />
                    <button onClick={searchGoogle}>Search</button>
                </div>
            )}

            {activeSearch === 'search-youtube' && (
                <div className='d-flex search-youtube-input'>
                    <input 
                        className='w-100' 
                        placeholder='Search YouTube' 
                        value={inputValue} 
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <button onClick={searchYouTube}>Search</button>
                </div>
            )}

            {activeSearch === 'search-chatgpt' && (

                <div className='d-flex flex-col align-start'>
                    <div className='d-flex search-chatgpt-input w-100'>
                        <input 
                            className='w-100' 
                            placeholder='Search ChatGPT' 
                            value={inputValue} 
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown} // Trigger search on Enter
                        />
                        <button onClick={searchChatGPT}>Search</button>
                    </div>
                    <div className='d-flex align-center col-gap-5 mt-5 align-baseline'>
                        <div style={{fontSize: "11px", color: "grey"}}>Please only add a few sentances max</div>
                        <a href="https://www.chatgpt.com" target="_blank" className='d-flex align-center col-gap-5 youtube-link' style={{fontSize: "11px"}}>
                            <div>ChatGPT</div>
                            <div className='d-flex align-center'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></div>
                        </a>
                    </div>
                    </div>
            )}
        </div>
    );
}

export default Search;
