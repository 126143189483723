const Faq = () => {
    return (
            <div className='text-center'>
                <br />
                <br />

                <h2>How is my data stored?</h2>

                <br />
                <br />

                <h2>💾 Data is saved to your device (your browser's local storage)</h2>

                <br/>
                <br/>

                <h2>This means that if you...</h2>
                <h4>❌ Delete your browsing history</h4>
                <h4>❌ Clear your cache</h4>
                <h4>❌ or delete cookies</h4>
                <h4>❌ use incognito mode</h4>
                <h4>You'll lose all your data❗️</h4>

                <br />
                <br />

                <h2>But you can... 😃</h2>

                <br/>
                <br/>

                <h2>💾 Download your data</h2>
                <h4>📄 As a file</h4>
                <h4>As a backup</h4>
                <h5>Think of it like saving a Microsoft Word file</h5>
                <h4>Then re-upload</h4>
                <h4>( ⬅️ in the sidebar )</h4>
                <h4>To any device</h4>

                <br />
                <br />

                <h3>Pros</h3>
                <h4>✅ No login</h4>
                <h4>✅ No Server</h4>
                <h4>✅ You own all your data</h4>
                <h4>✅ Each device is a blank slate</h4>
                <h5>✅ Create multiple workspaces with multiple files</h5>

                <br />
                <br />

                <h3>Cons</h3>
                <h4>❌ Need to save often</h4>
                <h4>❌ Difficult to use between devices</h4>
                <h4>❌ Data will dissapear if you clear your browser or use incognito mode</h4>

                <br />
                <br />
                <br />
                <br />

            </div>
    )
}

export default Faq;