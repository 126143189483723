import React, { useState } from 'react';
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
    KeyboardSensor
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    sortableKeyboardCoordinates,
    rectSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './bookmarks.css';

function Bookmarks() {
    const [bookmarks, setBookmarks] = useState([
        { id: '1', label: 'Google', href: 'https://www.google.com' },
        { id: '2', label: 'ChatGPT', href: 'https://www.chatgpt.com' },
        { id: '3', label: 'YouTube', href: 'https://www.youtube.com' },
        { id: '4', label: 'Spotify', href: 'https://open.spotify.com/' },
        // { id: '4', label: 'Lucide.dev', href: 'https://lucide.dev/icons/' },
        // { id: '5', label: 'DND Kit', href: 'https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com' },
        // { id: '6', label: 'Packery', href: 'https://packery.metafizzy.co/' },
        // { id: '7', label: 'Muuri', href: 'https://muuri.dev/' },
        // { id: '8', label: 'Shadcn/ui Cards', href: 'https://ui.shadcn.com/examples/cards' },
        // { id: '9', label: 'Tailwind Colors', href: 'https://redpixelthemes.com/blog/tailwindcss-colors-different-formats/' }
    ]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            setBookmarks((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const handleRemove = (id) => {
        setBookmarks((prev) => prev.filter((bookmark) => bookmark.id !== id));
    };

    return (
        <>

            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={bookmarks}
                    strategy={rectSortingStrategy} // Changed to rectSortingStrategy for better handling of grid layout
                >
                    <div className="bookmarks bookmarks-grid">
                    {/* <h5 className='d-flex align-center'>🔖 Bookmarks</h5> */}
                        {bookmarks.map(({ id, label, href }) => (
                            <SortableItem
                                key={id}
                                id={id}
                                label={label}
                                href={href}
                                onRemove={handleRemove}
                            />
                        ))}
                        {/* <button><span>+</span></button> */}
                    </div>
                </SortableContext>
            </DndContext>
        </>
    );
}

function SortableItem({ id, label, href, onRemove }) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || 'transform 150ms ease', // Ensure smooth transition
        opacity: isDragging ? 0.9 : 1, // Slightly reduce opacity during drag
        zIndex: isDragging ? 999 : 'auto', // Ensure the dragged item stays on top
    };

    return (
        <div ref={setNodeRef} style={style} className={`link-btn sortable-item ${label === 'Spotify' ? 'desktop-only' : ''}`} {...attributes}>
            {/* <div className="drag-handle" {...listeners}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip"><circle cx="12" cy="5" r="1"/><circle cx="19" cy="5" r="1"/><circle cx="5" cy="5" r="1"/><circle cx="12" cy="12" r="1"/><circle cx="19" cy="12" r="1"/><circle cx="5" cy="12" r="1"/><circle cx="12" cy="19" r="1"/><circle cx="19" cy="19" r="1"/><circle cx="5" cy="19" r="1"/></svg>    
                </span> 
            </div> */}
            
            <a href={href} target="_blank" rel="noopener noreferrer">
                <div className='link-btn-inner'>
                    <img src={`https://www.google.com/s2/favicons?domain=${href}&sz=512`} alt="logo image" />
                    <div>{label}</div>
                </div>
            </a>
            {/* <div className="remove-btn" onClick={() => onRemove(id)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </div> */}
        </div>
    );
}

export default Bookmarks;