import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';

import '../components/todolist.css'

const SortableItem = ({ id, todo, index, toggleTodo, startEditing, editingIndex, editingText, setEditingText, saveEdit, deleteTodo }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: transform ? `translateY(${transform.y}px)` : undefined,
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="checkbox item border-b">
      <div className="d-flex justify-between align-center w-100">

      <div className="drag-handle" {...listeners} {...attributes}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical">
            <circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" />
          </svg>
        </div>

        <div className='d-flex align-center col-gap-10 w-100'>
        <input type="checkbox" checked={todo.completed} onChange={() => toggleTodo(index)} />
          {editingIndex === index ? ( 
            <input
              className='input'
              type="text"
              value={editingText}
              onChange={(e) => setEditingText(e.target.value)}
              onBlur={() => saveEdit(index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') saveEdit(index);
              }}
              autoFocus
            />

          ) : (
            <div className={`w-100 inner ${todo.completed ? 'completed' : ''}`} onClick={() => startEditing(index)}>{todo.name}</div> 
          )}
          </div>
          <div className='d-flex col-gap-5'>
            {/* <div className='delete-btn'>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-notebook-pen"><path d="M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4"/><path d="M2 6h4"/><path d="M2 10h4"/><path d="M2 14h4"/><path d="M2 18h4"/><path d="M21.378 5.626a1 1 0 1 0-3.004-3.004l-5.01 5.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z"/></svg>
            </div> */}
            <div className='delete-btn' onClick={() => deleteTodo(index)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
            </div>
          </div>
      </div>
    </div>
  );
};

const ToDoList = ({ todos, setTodos, pageId, folderId, updateFolders }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingText, setEditingText] = useState('');

  useEffect(() => {
    if (folderId && pageId) {
    const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
    if (!workspaceData[folderId]) {
      workspaceData[folderId] = { pages: {} };
    }
    if (!workspaceData[folderId].pages[pageId]) {
      workspaceData[folderId].pages[pageId] = { todoList: [] };
    }
    workspaceData[folderId].pages[pageId].todoList = todos;
    localStorage.setItem("NoteWorkspace", JSON.stringify(workspaceData));
    }
  }, [todos, pageId, folderId]);

  const addTodo = () => {
    const newTodo = { name: `To Do Item ${todos.length + 1}`, completed: false };
    setTodos([...todos, newTodo]);
  };

  const toggleTodo = (index) => {
    const updatedTodos = todos.map((todo, i) => (i === index ? { ...todo, completed: !todo.completed } : todo));
    setTodos(updatedTodos);
  };

  const deleteTodo = (index) => {
    const updatedTodos = todos.filter((_, i) => i !== index);
    setTodos(updatedTodos);
  };

  const startEditing = (index) => {
    setEditingIndex(index);
    setEditingText(todos[index].name);
  };

  const saveEdit = (index) => {
    const updatedTodos = todos.map((todo, i) => (i === index ? { ...todo, name: editingText } : todo));
    setTodos(updatedTodos);
    setEditingIndex(null);
    setEditingText('');
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = todos.findIndex((todo) => todo.name === active.id);
      const newIndex = todos.findIndex((todo) => todo.name === over.id);
      const newTodos = arrayMove(todos, oldIndex, newIndex);
      setTodos(newTodos);

      // Update folders state with the new order
      const updatedFolders = JSON.parse(localStorage.getItem("NoteWorkspace"));
      
    // Ensure folder and page exist before attempting to update
    if (updatedFolders[folderId]?.pages?.[pageId]) {
      updatedFolders[folderId].pages[pageId].todoList = newTodos;
      localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
      updateFolders(updatedFolders);
    } else {
      console.warn(`Folder or page not found for folderId: ${folderId}, pageId: ${pageId}`);
    }
    }
  };

  return (
    <div className="todolist card">
      <div className="d-flex justify-between border-b align-center">
        <h4>☑️ To Do List</h4>
        <button onClick={addTodo}>+</button>
      </div>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext items={todos.map((todo) => todo.name)} strategy={verticalListSortingStrategy}>
          {todos.map((todo, index) => (
            <SortableItem
              key={todo.name}
              id={todo.name}
              todo={todo}
              index={index}
              toggleTodo={toggleTodo}
              startEditing={startEditing}
              editingIndex={editingIndex}
              editingText={editingText}
              setEditingText={setEditingText}
              saveEdit={saveEdit}
              deleteTodo={deleteTodo}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ToDoList;












// import React, { useState } from 'react';
// import '../components/todolist.css'

// const ToDoList = ({ todos, setTodos }) => {
//   const [editingIndex, setEditingIndex] = useState(null);
//   const [editingText, setEditingText] = useState('');

//   const addTodo = () => {
//     const nextTodoNumber = todos.length + 1;
//     const newTodo = { name: `To Do Item ${nextTodoNumber}`, completed: false };
//     setTodos([...todos, newTodo]);
//   };

//   const toggleTodo = (index) => {
//     const updatedTodos = todos.map((todo, i) =>
//       i === index ? { ...todo, completed: !todo.completed } : todo
//     );
//     setTodos(updatedTodos);
//   };

//   const deleteTodo = (index) => {
//     const updatedTodos = todos.filter((_, i) => i !== index);
//     setTodos(updatedTodos);
//   };

//   const startEditing = (index) => {
//     setEditingIndex(index);
//     setEditingText(todos[index].name);
//   };

//   const saveEdit = (index) => {
//     const updatedTodos = todos.map((todo, i) =>
//       i === index ? { ...todo, name: editingText } : todo
//     );
//     setTodos(updatedTodos);
//     setEditingIndex(null);
//     setEditingText('');
//   };

//   return (
//     <div className="todolist card">

//       <div className='d-flex justify-between border-b align-center'>
//         <h4>☑️ To Do List</h4>
//         <button onClick={addTodo}>+</button>
//       </div>

//       <div>
//         {todos.map((todo, index) => (
//           <div key={index} className={`checkbox item`}>
//             <div className="d-flex justify-between align-center w-100">
//               <div className='d-flex align-center col-gap-10 w-100'>

//                   <input type="checkbox" />
//                   {editingIndex === index ? ( 
//                     <input
//                       className='input'
//                       type="text"
//                       value={editingText}
//                       onChange={(e) => setEditingText(e.target.value)}
//                       onBlur={() => saveEdit(index)}
//                       onKeyDown={(e) => {
//                         if (e.key === 'Enter') saveEdit(index);
//                       }}
//                       autoFocus
//                     />

//                   ) : (
//                     <div className='w-100 inner' onClick={() => startEditing(index)}>{todo.name}</div> 
//                   )}
//                   </div>
//                     <div>
//                       <div className='delete-btn' onClick={() => deleteTodo(index)}>
//                         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
//                       </div>
//                     </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ToDoList;
