import React, { useState, useEffect, useRef } from 'react';

import ToDoList from './ToDoList';
import Notes from './Notes';
import Youtube from './Youtube';
import LinksList from './LinksList';
import Pomodoro from './Pomodoro';
import Jodit from './Jodit';


const Focus = ({ editorWidth, setEditorWidth, handleMouseDownEditor, isPageReady, setIsPageReady }) => {


    // const { folderId, pageId } = useParams();

    const folderId = "focus"
    const pageId = 0;

    const [folders, setFolders] = useState(() => {
        const savedFolders = JSON.parse(localStorage.getItem('NoteWorkspace')) || {};

        if (!savedFolders.focus) {
            savedFolders.focus = {
                name: "Focus",
                pages: {
                    0: {
                        name: "Page 1",
                        todoList: [{ name: "To Do Item 1", completed: false }],
                        linkList: [{ name: "Google", url: "https://www.google.com" }, { name: "ChatGPT", url: "https://www.chatgpt.com" }, { name: "YouTube", url: "https://www.youtube.com" }],
                        notes: [
                            { title: "Note 1", content: "<h1>Title: Note 1</h1><h2>Subtitle</h2><hr/><p>Text</p> <p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p>" }
                        ]
                    }
                }
            };
        }

        for (const folderId in savedFolders) {
            for (const pageId in savedFolders[folderId].pages) {
                const page = savedFolders[folderId].pages[pageId];
                page.todoList = page.todoList || [];
                page.linkList = page.linkList || [];
                page.notes = page.notes || [];
            }
        }
        return savedFolders;
    });

    useEffect(() => {
        localStorage.setItem('NoteWorkspace', JSON.stringify(folders));
    }, [folders]);

    const updateFolders = (newFolders) => setFolders(newFolders);

    const [notes, setNotes] = useState(folders.focus?.pages?.[0]?.notes || []);
    const [currentNote, setCurrentNote] = useState(0); // Initializing currentNote

    const [sectionHeight, setSectionHeight] = useState(500);
    const requestRef = useRef(null);
    const initialMouseY = useRef(null);


    const addNote = () => {
        const newNoteTitle = `Note ${notes.length + 1}`;
        const newNote = { title: newNoteTitle, content: `<h1>Focus</h1><h2>Subtitle</h2><hr/><p>Text</p> <p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p>` };

        const updatedNotes = [...notes, newNote];
        setNotes(updatedNotes);
        setCurrentNote(notes.length); // Set to the new note

        const updatedFolders = {
            ...folders,
            focus: {
                ...folders.focus,
                pages: {
                    0: { ...folders.focus.pages[0], notes: updatedNotes }
                }
            }
        };

        localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
        updateFolders(updatedFolders);
    };




    const selectNote = (index) => {
        const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};

        // Retrieve the latest note content from localStorage
        if (
            workspaceData[folderId] &&
            workspaceData[folderId].pages &&
            workspaceData[folderId].pages[pageId] &&
            workspaceData[folderId].pages[pageId].notes &&
            workspaceData[folderId].pages[pageId].notes[index]
        ) {
            const latestNotes = workspaceData[folderId].pages[pageId].notes;

            // Update `notes` state to sync with localStorage
            setNotes(latestNotes);
        }

        setCurrentNote(index);
    };

    const deleteNote = (index) => {
        const updatedNotes = notes.filter((_, i) => i !== index);
        console.log("Updated Notes Array:", updatedNotes);

        // Log each note individually
        updatedNotes.forEach((note, i) => {
            console.log(`Note ${i}:`, note);
        });
        if (window.confirm("Are you sure you want to delete this note?")) {
            // Filter out the deleted note from the notes array
            const updatedNotes = notes.filter((_, i) => i !== index);

            setNotes(updatedNotes);
            setCurrentNote(0); // Reset currentNote when a note is deleted

            // Update notes in local storage and in the parent folder structure
            const updatedFolders = {
                ...folders,
                focus: {
                    ...folders.focus,
                    pages: {
                        0: { ...folders.focus.pages[0], notes: updatedNotes }
                    }
                }
            };

            localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
            updateFolders(updatedFolders); // Ensure parent component updates with new structure
        }
    };

    const renameNote = (index, newTitle) => {
        const updatedNotes = notes.map((note, i) =>
            i === index ? { ...note, title: newTitle } : note
        );
        setNotes(updatedNotes);

        const updatedFolders = {
            ...folders,
            focus: {
                ...folders.focus,
                pages: {
                    0: { ...folders.focus.pages[0], notes: updatedNotes }
                }
            }
        };

        localStorage.setItem('NoteWorkspace', JSON.stringify(updatedFolders));
        updateFolders(updatedFolders);
    };

    const handleAddVideo = (folderId, pageId, videoId) => {
        console.log(folderId, pageId, videoId);
        if (folderId !== undefined && pageId !== undefined) { 
          const updatedFolders = {
            ...folders,
            [folderId]: {
              ...folders[folderId],
              pages: {
                ...folders[folderId].pages,
                [pageId]: {
                  ...folders[folderId].pages[pageId],
                  videoList: [
                    ...(folders[folderId].pages[pageId].videoList || []),
                    videoId,
                  ],
                },
              },
            },
          };
          setFolders(updatedFolders);
          localStorage.setItem('NoteWorkspace', JSON.stringify(updatedFolders));
        }
      };
      
      const handleDeleteVideo = (folderId, pageId, videoId) => {
        if (folderId !== undefined && pageId !== undefined) { 
          const updatedFolders = {
            ...folders,
            [folderId]: {
              ...folders[folderId],
              pages: {
                ...folders[folderId].pages,
                [pageId]: {
                  ...folders[folderId].pages[pageId],
                  videoList: folders[folderId].pages[pageId].videoList.filter(id => id !== videoId),
                },
              },
            },
          };
          setFolders(updatedFolders);
          localStorage.setItem('NoteWorkspace', JSON.stringify(updatedFolders));
        }
      };


    const handleMouseMove = (e) => {
        if (requestRef.current || initialMouseY.current === null) return;
        requestRef.current = requestAnimationFrame(() => {
            const newHeight = sectionHeight + (e.clientY - initialMouseY.current);
            setSectionHeight(Math.max(19, Math.min(newHeight, 800)));
            requestRef.current = null;
        });
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        initialMouseY.current = null;
    };

    const handleMouseDown = (e) => {
        initialMouseY.current = e.clientY;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };


    return (
        <>
            <div className='content-inner d-flex col-gap-1rem'>
                <div className='first-section d-flex flex-col'>

                    <div className='first-container d-flex col-gap-1rem relative z-1' style={{ height: `${sectionHeight}px` }}>
                        <div className='todo-border'></div>
                        <div className="section-resize" onMouseDown={handleMouseDown}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical"><circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" /></svg>
                        </div>
                        <div className='d-flex flex-col w-100'>
                            <h2 className='page-title mb-10 text-left'>🎧 Focus On 1 Task</h2>
                            {/* <div className='mb-15'><Bookmarks/></div> */}


                            <div className='first-container-inner d-flex col-gap-1rem'>
                                <div className='w-60'>
                                    <ToDoList
                                        todos={folders.focus?.pages[0]?.todoList || []}
                                        setTodos={(newTodos) => {
                                            const updatedFolders = {
                                                ...folders,
                                                focus: {
                                                    ...folders.focus,
                                                    pages: {
                                                        0: { ...folders.focus.pages[0], todoList: newTodos }
                                                    }
                                                }
                                            };
                                            updateFolders(updatedFolders); // Update folders and save to localStorage in updateFolders
                                            localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders)); // Directly save to local storage
                                        }}
                                        pageId={0}
                                        folderId="focus"
                                    />
                                </div>

                                <div className='w-40 d-flex flex-col row-gap-1rem'>
                                    <Pomodoro />

                                    <Notes
                                        folders={folders}
                                        notes={notes}
                                        setNotes={setNotes}
                                        addNote={addNote}
                                        selectNote={selectNote}
                                        renameNote={renameNote}
                                        deleteNote={deleteNote}

                                        folderId="focus"
                                        pageId="0"
                                        updateFolders={updateFolders}
                                    />
                                    <LinksList
                                        links={folders.focus?.pages[0]?.linkList || []}
                                        setLinks={(newLinks) => {
                                            const updatedFolders = {
                                                ...folders,
                                                focus: {
                                                    ...folders.focus,
                                                    pages: {
                                                        0: { ...folders.focus.pages[0], linkList: newLinks }
                                                    }
                                                },
                                            };
                                            updateFolders(updatedFolders);
                                            localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders)); // Directly save to local storage
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>

                    <Youtube
                        videoIds={folders.focus?.pages[0]?.videoList || []}
                        onAddVideo={handleAddVideo}
                        onDeleteVideo={handleDeleteVideo}
                        folderId="focus"
                        pageId={0}      
                    />

                </div>

                {/* <div className="w-50 jodit-flex-container"> */}

                <Jodit
                    notes={notes}
                    setNotes={setNotes}
                    currentNote={currentNote}
                    addNote={addNote}
                    selectNote={selectNote}
                    deleteNote={deleteNote}

                    folderId={"focus"} pageId={0}
                    editorWidth={editorWidth}
                    setEditorWidth={setEditorWidth}
                    handleMouseDownEditor={handleMouseDownEditor}
                    isPageReady={isPageReady}
                    setIsPageReady={setIsPageReady}
                />

            </div>
        </>
    )
}

export default Focus