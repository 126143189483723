import React, { useState, useEffect, useRef } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import './styles/notes.css';

const Notes = ({ notes, setNotes, addNote, selectNote, deleteNote, renameNote, updateFolders, folders, folderId, pageId }) => {
    const [currentNotes, setCurrentNotes] = useState(notes);
    const [renameIndex, setRenameIndex] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const popupRef = useRef(null);

    useEffect(() => {

        setCurrentNotes(
            notes.map(note => ({
                ...note,
                id: note.id || `${Math.random().toString(36).substr(2, 9)}`, // Generate unique ID if missing
            }))
        );
    }, [notes]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleRenameClick = (index) => {
        setRenameIndex(index);
        setNewTitle(currentNotes[index].title);
    };

    const handleRenameSave = (index) => {
        renameNote(index, newTitle);
        setRenameIndex(null);
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            handleRenameSave(index);
        }
    };

    const togglePopup = (noteId) => {
        setOpenMenu(openMenu === noteId ? null : noteId);
    };

const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
        const oldIndex = currentNotes.findIndex((note) => note.id === active.id);
        const newIndex = currentNotes.findIndex((note) => note.id === over.id);
        const newNotes = arrayMove(currentNotes, oldIndex, newIndex);
        setNotes(newNotes);
        setCurrentNotes(newNotes);
        saveOrderToLocalStorage(newNotes);

        // // Update the main notes array in the parent
        // updateFolders((prevFolders) => {
        //     const updatedFolders = {
        //         ...prevFolders,
        //         home: {
        //             ...prevFolders.home,
        //             pages: {
        //                 ...prevFolders.home.pages,
        //                 0: { ...prevFolders.home.pages[0], notes: newNotes }
        //             }
        //         }
        //     };
        //     localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
        //     return updatedFolders;
        // });
    }
};

    const saveOrderToLocalStorage = (newNotes) => {
        if (!folders) {
            // If folders or folders.home is undefined, exit early or log an error for debugging
            console.error("Folders or folders.home is not defined");
            return;
        }
    
        let updatedFolders;
    
        if (folderId && pageId) {
            // Case for pages with folderId and pageId
            updatedFolders = {
                ...folders,
                [folderId]: {
                    ...folders[folderId],
                    pages: {
                        ...folders[folderId].pages,
                        [pageId]: { ...folders[folderId].pages[pageId], notes: newNotes }
                    }
                }
            };
        } 
    
        localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
        updateFolders(updatedFolders); // Update the folder structure in the parent component
    };
    

    const SortableNote = ({ id, note, index }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (
            <div ref={setNodeRef} style={style} className="item">
                <div className="drag-handle" {...listeners} {...attributes}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical">
                        <circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" />
                    </svg>
                </div>
                {renameIndex === index ? (
                    <input
                        type="text"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        onBlur={() => handleRenameSave(index)}
                        onKeyPress={(e) => handleKeyPress(e, index)}
                        className="rename-input"
                        autoFocus
                    />
                ) : (
                    <div className="select-note-btn" onClick={() => selectNote(index)}>
                        📝 {note.title}
                    </div>
                )}


                    {/* Ellipsis icon with tooltip and popup menu */}
                    <div className='folder-menu folder-menutooltip tooltip' onClick={() => togglePopup(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ellipsis">
                        <circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" />
                      </svg>
                      <span className="tooltip-text">Options</span>
                    </div>


                    {openMenu === index && (
                    <>
                        <div ref={popupRef} className={`popup-menu show-popup ${openMenu.index === index ? 'show-popup' : ''}`}>
                            <button onClick={() => {handleRenameClick(index);setOpenMenu(null)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg>
                                Rename
                            </button>
                            <button className='delete-note-btn' onClick={() => {deleteNote(index);setOpenMenu(null)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                Delete
                            </button>
                        </div>

                    </>
                    )}


            </div>
        );
    };

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
            <SortableContext items={currentNotes.map((note) => note.id)} strategy={verticalListSortingStrategy}>
                <div className='card'>
                    <div className="note-list relative">
                        <div className="d-flex justify-between border-b align-center">
                            <h4>📝 Notes</h4>
                            <button className="add-note-btn" onClick={addNote}>+</button>
                        </div>
                        {currentNotes.map((note, index) => (
                            <SortableNote
                                key={note.id}
                                id={note.id}
                                note={note}
                                index={index}
                            />
                        ))}
                    </div>
                </div>
            </SortableContext>
        </DndContext>
    );
};

export default Notes;




















// import React, { useState, useEffect, useRef } from 'react';
// import './styles/notes.css'

// const Notes = ({ notes, addNote, selectNote, deleteNote, renameNote, folders }) => {
//     const [currentNote, setCurrentNote] = useState(0);
//     const [renameIndex, setRenameIndex] = useState(null); // Track which note is being renamed
//     const [newTitle, setNewTitle] = useState(''); // Temporary title state for renaming
//     const [openMenu, setOpenMenu] = useState(null); // Track which folder's popup is open
//     const popupRef = useRef(null);

//         useEffect(() => {
//             // console.log(window.location.pathname)
//     }, [window.location.pathname]);

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (popupRef.current && !popupRef.current.contains(event.target)) {
//                 setOpenMenu(null);
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => document.removeEventListener('mousedown', handleClickOutside);
//     }, []);

//     // Trigger rename mode for a specific note
//     const handleRenameClick = (index) => {
//         setRenameIndex(index); // Set which note is being renamed
//         setNewTitle(notes[index].title); // Pre-fill the current title in the input
//     };

//     // Save the new title and exit rename mode
//     const handleRenameSave = (index) => {
//         renameNote(index, newTitle); // Call the renameNote function passed from parent
//         setRenameIndex(null); // Exit rename mode
//     };

//     // Handle Enter key press to save the rename
//     const handleKeyPress = (e, index) => {
//         if (e.key === 'Enter') {
//             handleRenameSave(index);
//         }
//     };

//     const togglePopup = (folderId) => {
//         setOpenMenu(openMenu === folderId ? null : folderId);
//     };

//     return (
//         <div className='card'>
//             <div className="note-list relative">

//             <div className='d-flex justify-between border-b align-center'>
//                 <h4>📝 Notes</h4>
//                 <button className='add-note-btn' onClick={addNote}>+</button>
//             </div>

//                 {notes.map((note, index) => (
//                     <div key={index} className='item'>
//                         {/* If renaming, show input; else show title */}
//                         {renameIndex === index ? (
//                             <input
//                                 type="text"
//                                 value={newTitle}
//                                 onChange={(e) => setNewTitle(e.target.value)}
//                                 onBlur={() => handleRenameSave(index)} // Save on blur
//                                 onKeyPress={(e) => handleKeyPress(e, index)} // Save on Enter
//                                 className='rename-input'
//                                 autoFocus
//                             />
//                         ) : (
//                             <div className='select-note-btn' onClick={() => selectNote(index)}>
//                                 📝 {note.title}
//                             </div>
//                         )}

//                     {/* Ellipsis icon with tooltip and popup menu */}
//                     <div className='folder-menu folder-menutooltip tooltip' onClick={() => togglePopup(index)}>
//                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ellipsis">
//                         <circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" />
//                       </svg>
//                       <span className="tooltip-text">Options</span>
//                     </div>


//                     {openMenu === index && (
//                     <>
//                         <div ref={popupRef} className={`popup-menu show-popup ${openMenu.index === index ? 'show-popup' : ''}`}>
//                             <button onClick={() => handleRenameClick(index)}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg>
//                                 Rename
//                             </button>
//                             <button className='delete-note-btn' onClick={() => deleteNote(index)}>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
//                                 Delete
//                             </button>
//                         </div>

//                     </>
//                     )}


//                     </div>
//                 ))}
//             </div>
//         </div>
//     )
// }

// export default Notes;