import React from 'react';

import SidebarPage from './SidebarPage'

const SidebarFolder = ({
    folderId, toggleFolder, editingFolderId, setCurrentNote, newFolderNameInput, setNewFolderNameInput,
    handleRenameFolder, folderOpen, folder, togglePopup, openMenu, popupRef, setEditingFolderId, setOpenMenu,
    deleteFolder, editingPageId, editingFolderForPageId, newPageNameInput, setNewPageNameInput,
    handleRenamePage, togglePopupPage, setEditingPageId, setEditingFolderForPageId, deletePage, addPage, 
    updateFolders, folders, closeSidebar
}) => {

    return (
        <>

            <div key={folderId} className="folder-inner d-flex flex-col w-100">
                    <>
                        <div className="folder-header d-flex justify-between align-center">

                            <div className='item' onClick={() => toggleFolder(folderId)}>
                                {/* Conditionally render input for renaming */}
                                {editingFolderId === folderId ? (
                                    <input
                                        type="text"
                                        value={newFolderNameInput}
                                        onChange={(e) => setNewFolderNameInput(e.target.value)}
                                        onBlur={() => handleRenameFolder(folderId)} // Save on blur
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleRenameFolder(folderId); // Save on Enter
                                        }}
                                        autoFocus
                                    />

                                ) : (

                                    <>
                                        {folderOpen[folderId] ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg>
                                        }

                                        📁 {folder.name}

                                    </>
                                )}

                            </div>

                            {/* Ellipsis icon with tooltip and popup menu */}
                            <div className='folder-menu folder-menutooltip tooltip' onClick={() => togglePopup(folderId)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ellipsis">
                                    <circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" />
                                </svg>
                                <span className="tooltip-text">Options</span>
                            </div>

                        </div>
                        <>


                            {openMenu === folderId && (
                                <>

                                    <div ref={popupRef} className={`popup-menu show-popup ${openMenu.folderId === folder.id ? 'show-popup' : ''}`}>
                                        <button onClick={() => {
                                            setEditingFolderId(folderId); // Set folder to edit mode
                                            setNewFolderNameInput(folder.name); // Pre-fill input with current name
                                            togglePopup(folderId);
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" /><path d="m15 5 4 4" /></svg>
                                            Rename
                                        </button>

                                        <button onClick={() => { deleteFolder(folderId); togglePopup(folderId) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                            Delete</button>
                                    </div>

                                </>
                            )}
                        </>
                    </>








                <SidebarPage
                    folderOpen={folderOpen}
                    folderId={folderId}
                    folder={folder}
                    setCurrentNote={setCurrentNote}
                    editingPageId={editingPageId}
                    editingFolderForPageId={editingFolderForPageId}
                    newPageNameInput={newPageNameInput}
                    setNewPageNameInput={setNewPageNameInput}
                    handleRenamePage={handleRenamePage}
                    togglePopupPage={togglePopupPage}
                    openMenu={openMenu}
                    popupRef={popupRef}
                    setEditingPageId={setEditingPageId}
                    setOpenMenu={setOpenMenu}
                    closeSidebar={closeSidebar}

                    setEditingFolderForPageId={setEditingFolderForPageId}
                    togglePopup={togglePopup}
                    deletePage={deletePage}
                    addPage={addPage}
                    folders={folders}
                    updateFolders={updateFolders}
                />




            </div>

        </>
    )
}

export default SidebarFolder;