import React, { useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import './styles/bookmarkspage.css';

const SortableItem = ({ id, link, deleteLink }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} className="link-item" {...attributes}>
            <div className="drag-handle" {...listeners}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="5" r="1" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="12" cy="19" r="1" />
                    <circle cx="19" cy="5" r="1" />
                    <circle cx="19" cy="12" r="1" />
                    <circle cx="19" cy="19" r="1" />
                    <circle cx="5" cy="5" r="1" />
                    <circle cx="5" cy="12" r="1" />
                    <circle cx="5" cy="19" r="1" />
                </svg>
            </div>
            <a 
                href={link.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="link-text d-flex align-center col-gap-5"
                data-fulltext={link.name} /* Tooltip text on hover */
            >
                <img src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=512`} alt="logo image" className="favicon" />
                {link.name}
            </a>
            <button onClick={() => deleteLink(id)} className="delete-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
        </div>
    );
};

const BookmarksPage = () => {
    const [links, setLinks] = useState([]);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        // Default links to save if the folder is empty
        const defaultLinks = {
            items: [
                { id: "link-1730769496015", name: "Google", url: "https://www.google.com" },
                { id: "link-1730769513400", name: "ChatGPT", url: "https://www.chatgpt.com" },
                { id: "link-1730769533772", name: "YouTube", url: "https://www.youtube.com" },
                { id: "link-1730771095155", name: "Google Maps", url: "https://www.google.com/maps" },
                { id: "link-1730771072109", name: "Gmail", url: "https://mail.google.com/mail/u/0/#inbox" },
                { id: "link-1730771758748", name: "Notion", url: "https://www.notion.so/" },
                { id: "link-1730772395446", name: "Midjourney", url: "https://www.midjourney.com/" },
                { id: "link-1730772439661", name: "Runway ML", url: "https://app.runwayml.com/" },
                { id: "link-1730772518503", name: "Claude", url: "https://claude.ai/new" },
                { id: "link-1730772546855", name: "ElevenLabs", url: "https://elevenlabs.io/" },
                { id: "link-1730772605927", name: "Perplexity.ai", url: "https://www.perplexity.ai/" },
                { id: "link-1730772591345", name: "Captions.ai", url: "https://www.captions.ai/" },
                { id: "link-1730771021723", name: "Canva", url: "https://www.canva.com" },
                { id: "link-1730772696124", name: "Figma", url: "https://www.figma.com/" },
                { id: "link-1730772704044", name: "Figjam", url: "https://www.figma.com/figjam/" },
                { id: "link-1730772576195", name: "Lumalabs.ai", url: "https://lumalabs.ai/dream-machine" },
                { id: "link-1730772535753", name: "Stability.ai", url: "https://assistant.stability.ai/" },
                { id: "link-1730772503429", name: "Gamma", url: "https://gamma.app/" },
                { id: "link-1730771656592", name: "LinkedIn", url: "https://www.linkedin.com" },
                { id: "link-1730771040274", name: "Reddit", url: "https://www.reddit.com" },
                { id: "link-1730772839893", name: "Instagram", url: "https://www.instagram.com/" },
                { id: "link-1730772879118", name: "X", url: "https://x.com/home" },
                { id: "link-1730772856377", name: "TikTok", url: "https://www.tiktok.com/" },
                { id: "link-1730772821799", name: "Facebook", url: "https://www.facebook.com/" }
            ],
            sidebar: "permanent"
        };
    
        // Load links from localStorage or initialize with default links
        const savedData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
        const savedLinks = savedData.links?.items || [];
        
        if (savedLinks.length === 0) {
            // If no links, save default links to local storage
            savedData.links = defaultLinks;
            localStorage.setItem("NoteWorkspace", JSON.stringify(savedData));
            setLinks(defaultLinks.items); // Set state with default links
        } else {
            setLinks(savedLinks); // Load saved links
        }
    }, []);
    

    useEffect(() => {
        // Load links from localStorage on mount
        const savedData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
        const savedLinks = savedData.links?.items || [];
        if (savedLinks.length) {
            setLinks(savedLinks);
        }
    }, []);

    useEffect(() => {
        // Only save to localStorage if links is not empty
        if (links.length > 0) {
            const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
            workspaceData.links = {
                items: links,           // Store the links array under "items"
                sidebar: "permanent"    // Add "sidebar: permanent" within the links object
            };
            localStorage.setItem("NoteWorkspace", JSON.stringify(workspaceData));
        }
    }, [links]);

    const addLink = (e) => {
        e.preventDefault();
        const newLink = { id: `link-${Date.now()}`, name, url };
        setLinks([newLink, ...links]);
        setName("");  // Reset form fields
        setUrl("");
    };

    const deleteLink = (id) => {
        const updatedLinks = links.filter(link => link.id !== id);
        setLinks(updatedLinks);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = links.findIndex(link => link.id === active.id);
            const newIndex = links.findIndex(link => link.id === over.id);
            const newLinks = arrayMove(links, oldIndex, newIndex);
            setLinks(newLinks);
        }
    };

    return (
        <div className="links-grid-container">
            <div className="header">
                <h3>📌 Bookmarks</h3>
                <form onSubmit={addLink} className="add-link-form">
                    <input 
                        type="text" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        placeholder="Link Name" 
                        required 
                    />
                    <input 
                        type="url" 
                        value={url} 
                        onChange={(e) => setUrl(e.target.value)} 
                        placeholder="Link URL" 
                        required 
                    />
                    <button type="submit">Add Link</button>
                </form>
            </div>
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToWindowEdges]}
            >
                <SortableContext items={links.map(link => link.id)} strategy={rectSortingStrategy}>
                    <div className="links-grid card">
                        {links.map(link => (
                            <SortableItem key={link.id} id={link.id} link={link} deleteLink={deleteLink} />
                        ))}
                    </div>
                </SortableContext>
            </DndContext>
        </div>
    );
};

export default BookmarksPage;
