// Jodit.js
import React, { useState, useEffect, useRef } from 'react';
import JoditEditor from 'jodit-react';
import "../components/jodit.css";

function Jodit({ notes, setNotes, folderId, pageId, currentNote, editorWidth, setEditorWidth, handleMouseDownEditor, isPageReady, setIsPageReady }) {
    // const [currentNote, setCurrentNote] = useState(0);
    const [isTyping, setIsTyping] = useState(false);
    // const requestRef = useRef(null); // Ref to control requestAnimationFrame
    const [openEditor, setOpenEditor] = useState(false);
    const [makeLarger, setMakeLarger] = useState(false);
    const editorRef = useRef(null);

    useEffect(() => {
    }, [currentNote]);


    const handleContentChange = (content) => {
        // if (!isPageReady) return;
        const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
    
        if (!workspaceData[folderId] || !workspaceData[folderId].pages[pageId] || !workspaceData[folderId].pages[pageId].notes[currentNote]) {
            // Exit early if the required structure doesn't exist yet
            return;
        }
    
        if (!workspaceData[folderId]) workspaceData[folderId] = { pages: {} };
        if (!workspaceData[folderId].pages[pageId]) workspaceData[folderId].pages[pageId] = { notes: [] };
    
        // Proceed with updating note content
        workspaceData[folderId].pages[pageId].notes[currentNote] = {
            ...workspaceData[folderId].pages[pageId].notes[currentNote],
            content,
        };
    
        localStorage.setItem("NoteWorkspace", JSON.stringify(workspaceData));
        // Update the `notes` state in `Page.js` with the updated notes array
        // const updatedNotes = workspaceData[folderId].pages[pageId].notes;
        // setNotes(updatedNotes);
        // Reset `isPageReady` back to `false` to avoid unnecessary calls
        // setIsPageReady(false);
    };    


// const handleContentChange = (content) => {
//     if (!isPageReady) return;

//     const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};

//     // Ensure folder, page, and note structure exists
//     if (!workspaceData[folderId]) workspaceData[folderId] = { pages: {} };
//     if (!workspaceData[folderId].pages[pageId]) workspaceData[folderId].pages[pageId] = { notes: [] };

//     // Check if `currentNote` is within bounds of the notes array
//     if (!workspaceData[folderId].pages[pageId].notes[currentNote]) {
//         // Exit early if the specific note is not available
//         return;
//     }

//     // Proceed with updating note content
//     workspaceData[folderId].pages[pageId].notes[currentNote] = {
//         ...workspaceData[folderId].pages[pageId].notes[currentNote],
//         content,
//     };

//     localStorage.setItem("NoteWorkspace", JSON.stringify(workspaceData));
//     setIsPageReady(false); // Reset isPageReady
// };



    // // Handle content changes by saving directly to localStorage
    // const handleContentChange = (content) => {
    //     const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
    
    //     if (!workspaceData[folderId]) workspaceData[folderId] = { pages: {} };
    //     if (!workspaceData[folderId].pages[pageId]) workspaceData[folderId].pages[pageId] = { notes: [] };
    
    //     // Ensure that the notes array is long enough to contain `currentNote`
    //     if (currentNote !== null && typeof currentNote === "number" && !workspaceData[folderId].pages[pageId].notes[currentNote]) {
    //         workspaceData[folderId].pages[pageId].notes[currentNote] = { title: "New Note", content: "<h1>Jodit</h1>" };
    //     }
    
    //     // Check again if the note at `currentNote` exists before accessing it
    //     if (workspaceData[folderId].pages[pageId].notes[currentNote]) {
    //         workspaceData[folderId].pages[pageId].notes[currentNote] = {
    //             ...workspaceData[folderId].pages[pageId].notes[currentNote],
    //             content,
    //         };
    
    //         localStorage.setItem("NoteWorkspace", JSON.stringify(workspaceData));
    //     }
    // };
    

    // Save button updates the React state `notes`
    const saveTextState = () => {
            const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
            // Update the `notes` state in `Page.js` with the updated notes array
            const updatedNotes = workspaceData[folderId].pages[pageId].notes;
            setNotes(updatedNotes);  // Update notes state
            setIsTyping(false);
    };

    const handleClickOutside = (event) => {
        if (editorRef.current && editorRef.current.container && !editorRef.current.container.contains(event.target)) {
            saveTextState();
            console.log("State Saved");
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const toggleEditorResize = () => {
        setOpenEditor(!openEditor);
        setEditorWidth(45);
    }

    // const toggleMakeLarger = () => {
    //     setMakeLarger(true)
    // }


    // Customize toolbar buttons
    const config = {
        minwidth: 400,
        width: '100%',
        height: '100vh',
        toolbarSticky: false,
        colors: {
            greyscale: ['#000000', '#434343', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette: ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'background',
        buttons: [
            'bold', 'strikethrough', 'underline', 'italic', '|', 'ul', 'ol', '|',
            'outdent', 'indent', '|', 'paragraph', 'font', 'fontsize', 'brush', 'image', '|',
            'link', '|', 'align', 'undo', 'redo', '|', 'hr', '|', 'print'
        ],
        defaultMode: '1',
        enter: 'P',
        defaultTag: 'p',
        direction: 'ltr',
        toolbarAdaptive: false,
        buttonsXS: ['align', 'undo', 'redo', 'bold', 'italic', 'underline', 'strikethrough'],
        allowResizeX: false,
        allowResizeY: false,
        toolbarButtonSize: 'middle',
        align: 'left',
        defaultActionOnPaste: 'insert_as_html',
        askBeforePasteHTML: false,
        commandToHotkeys: {
            indent: ['tab'],
            outdent: ['shift+tab'],
            strikethrough: ['command+shift+s', 'ctrl+shift+s']
        },
        uploader: {
            insertImageAsBase64URI: true  // This tells Jodit to insert images as base64 strings
        },
        events: {
            focus: () => {
                setIsTyping(true); // Set typing state when editor is focused
            },
            // keyup: () => {
            //     setIsTyping(true); // Trigger typing state
            // },
            blur: () => {
                saveTextState(); // Trigger save when the editor loses focus
            }
        }
    };

    return (
        // <div className='d-flex flex-col align-end row-gap-5 card'>
        //     <div className='d-flex justify-between align-center w-100'>
        //         <h4>{notes[currentNote].title}</h4>
        //         <button className={`py-10 px-20 note-save-btn ${isTyping ? 'color' : ''}`} onClick={saveTextState} >Save</button>
        //     </div>

        //     {currentNote !== null && notes[currentNote] && (
        //         <div className='max-w-100'>

        //             <div className="editor-container">
        //                 <JoditEditor
        //                     value={notes[currentNote].content}
        //                     config={config}
        //                     tabIndex={1}
        //                     onChange={handleContentChange}
        //                 />
        //             </div>
        //         </div>
        //     )}
        // </div>




            <>                        
            {notes[currentNote] && notes[currentNote].title !== undefined && (
                <div className={`${openEditor ? 'editor-new-window' : 'editor-wrapper'}`} style={{ width: `${editorWidth}%` }}>
                
                    <div className={`editor-resize-two ${makeLarger ? '' : 'd-none'}`} onMouseDown={handleMouseDownEditor} onMouseUp={() => {setMakeLarger(false);}}></div>
                    <div className="editor-resize" onMouseDown={() => {handleMouseDownEditor(); setMakeLarger(true);}} onMouseUp={() => {setMakeLarger(false);}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical"><circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" /></svg>
                    </div>

                    <div className='editor-padding d-flex flex-col align-end row-gap-5 card relative'>

                    <div className='expand-editor' onClick={toggleEditorResize}>
                        {openEditor ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-minimize-2"><polyline points="4 14 10 14 10 20"/><polyline points="20 10 14 10 14 4"/><line x1="14" x2="21" y1="10" y2="3"/><line x1="3" x2="10" y1="21" y2="14"/></svg>
                        :
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-maximize-2"><polyline points="15 3 21 3 21 9"/><polyline points="9 21 3 21 3 15"/><line x1="21" x2="14" y1="3" y2="10"/><line x1="3" x2="10" y1="21" y2="14"/></svg>
                        }
                    </div>

                        <div className='d-flex justify-between align-center w-100'>
                            <h4>{notes[currentNote]?.title}</h4>
                            <button className={`py-10 px-20 note-save-btn ${isTyping ? 'color' : ''}`} onClick={saveTextState} >Save</button>
                        </div>

                        {currentNote !== null && notes[currentNote] && (
                            <div className='max-w-100'>

                                <div className="editor-container">
                                    <JoditEditor
                                        ref={editorRef} 
                                        value={notes[currentNote]?.content || ""}
                                        config={config}
                                        tabIndex={1}
                                        onChange={handleContentChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            </>
    );
}

export default Jodit;
