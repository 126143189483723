// https://github.com/astroud/pomodoro-react-app
// https://github.com/kevinsqi/react-circular-progressbar


import React, { useState, useEffect, useRef } from 'react';
// import Draggable from 'react-draggable'; // Ensure this is imported
import './styles/timer.css'; // Ensure to import your CSS file

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Pomodoro = ({ timerTab }) => {
    const [time, setTime] = useState(1500); // Time in seconds
    const [isActive, setIsActive] = useState(false);
    const [timerDuration, setTimerDuration] = useState(1500);
    const [startBtn, setStartBtn] = useState(true);
    const [timeBtn, setTimeBtn] = useState(1500);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (isActive) {
            intervalRef.current = setInterval(() => {
                setTime(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(intervalRef.current);
                        setIsActive(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [isActive]);

    // const startTimer = () => setIsActive(true);
    // const pauseTimer = () => setIsActive(false);
    const toggleTimer = () => {
        setIsActive(!isActive);
    }
    const resetTimer = () => {
        setIsActive(false);
        setTime(timerDuration);
    };

    const setTimer = (minutes) => {
        setTimerDuration(minutes * 60);
        setTime(minutes * 60);
        setTimeBtn(minutes * 60);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const toggleStartBtn = () => {
        setStartBtn(!startBtn);
    }

    const progressPercentage = (time / timerDuration) * 100;

    return (
        <>
        {/* <div className={`${timerTab ? '' : 'd-none'}`}> */}
        <div className={`${timerTab} card bg-black`}>
            <div className="timer-display">
                {/* {formatTime(time)} */}
                <div className="run-timer" >
                    <span onClick={() => { toggleTimer(); toggleStartBtn(); }} >
                        {/* Circular Progressbar */}
                        <CircularProgressbar
                            onClick={() => { toggleTimer(); toggleStartBtn(); }}
                            counterClockwise={true}
                            value={progressPercentage}
                            text={formatTime(time)}
                            styles={buildStyles({
                                // Custom styling for the progress bar
                                strokeWidth: 2, // Adjust this value to make the progress bar thinner
                                pathColor: `rgba(216, 129, 248, ${progressPercentage / 99})`,
                                textColor: 'white',
                                // trailColor: '#1e213f',
                                trailColor: 'black',
                                backgroundColor: '#3e98c7',
                            })}
                        /></span>
                    <div className="start-container">
                        {startBtn ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-play"><polygon points="6 3 20 12 6 21 6 3" /></svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pause"><rect x="14" y="4" width="4" height="16" rx="1" /><rect x="6" y="4" width="4" height="16" rx="1" /></svg>
                        }
                    </div>
                </div>

                <div className="reset-container">
                    <button onClick={() => { resetTimer(); setStartBtn(true); }}>
                        Clear
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-rotate-ccw"><path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" /><path d="M3 3v5h5" /></svg> */}
                    </button>
                </div>
            </div>


            <div className="button-group">
                <div className='d-flex justify-center col-gap-10'>
                    <button onClick={() => setTimer(5)} className={`${timeBtn === 300 ? 'timer-btn-active' : ''}`}>5 Min</button>
                    <button onClick={() => setTimer(10)} className={`${timeBtn === 600 ? 'timer-btn-active' : ''}`}>10 Min</button>
                </div>
                <div className='d-flex justify-center col-gap-10'>
                    <button onClick={() => setTimer(15)} className={`${timeBtn === 900 ? 'timer-btn-active' : ''}`}>15 Min</button>
                    <button onClick={() => setTimer(25)} className={`${timeBtn === 1500 ? 'timer-btn-active' : ''}`}>25 Min</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default Pomodoro;













// const Pomodoro = () => {
//     return (
//         <>
//             <div className='card'>
//                 <div className="pomodoro relative">
//                     <div className="d-flex justify-between border-b align-center">
//                         <h4>Pomodoro</h4>
//                     </div>

//                 </div>
//             </div>
//         </>
//     )
// }

// export default Pomodoro;