import React, { useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { useSortable, SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import '../components/links.css';
import './styles/linklist.css';

const SortableLinkItem = ({ id, link, index, deleteLink }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: transform ? `translateY(${transform.y}px)` : undefined,
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className='item d-flex justify-between p-5'>
      <div className="drag-handle" {...listeners} {...attributes}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical">
          <circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" />
        </svg>
      </div>
      <div className='w-100'>
        <a href={link.url} target="_blank" rel="noopener noreferrer" className='d-flex align-center col-gap-5'>
          <img src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=512`} alt="logo" className="favicon" />
          {link.name}
        </a>
      </div>
      <div className='delete-link' onClick={() => deleteLink(index)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2">
          <path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" />
        </svg>
      </div>
    </div>
  );
};

const LinksList = ({ links, setLinks, folderId, pageId }) => {
  const [newLink, setNewLink] = useState('');
  const [newLinkName, setNewLinkName] = useState('');
  const [linkOpen, setLinkOpen] = useState(false);

  const toggleAddLink = () => setLinkOpen(!linkOpen);

  const addLink = () => {
    if (newLink.trim()) {
      const updatedLinks = [...links, { name: newLinkName || newLink, url: newLink }];
      setLinks(updatedLinks);
      setNewLink('');
      setNewLinkName('');
      saveToLocalStorage(updatedLinks);
    }
  };

  const deleteLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    saveToLocalStorage(updatedLinks);
  };

  const saveToLocalStorage = (updatedLinks) => {
    const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};
    if (!workspaceData[folderId]) {
      workspaceData[folderId] = { pages: {} };
    }
    if (!workspaceData[folderId].pages[pageId]) {
      workspaceData[folderId].pages[pageId] = {};
    }
    workspaceData[folderId].pages[pageId].linkList = updatedLinks;
    localStorage.setItem("NoteWorkspace", JSON.stringify(workspaceData));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = links.findIndex((link) => link.name === active.id);
      const newIndex = links.findIndex((link) => link.name === over.id);
      const sortedLinks = arrayMove(links, oldIndex, newIndex);
      setLinks(sortedLinks);
      saveToLocalStorage(sortedLinks);
    }
  };

  return (
    <div className='links card'>
      <div className='d-flex justify-between align-center'>
        <h4>🔗 Links</h4>
        <button className='add-link-btn' onClick={toggleAddLink}>{linkOpen ? '-' : '+'}</button>
      </div>

      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext items={links.map((link) => link.name)} strategy={verticalListSortingStrategy}>
          {links.map((link, index) => (
            <SortableLinkItem
              key={link.name}
              id={link.name}
              link={link}
              index={index}
              deleteLink={deleteLink}
            />
          ))}
        </SortableContext>
      </DndContext>

      {linkOpen && (
        <div className='d-flex flex-col mt-20 align-end'>
          <input
            type="text"
            placeholder="Link Name (optional)"
            value={newLinkName}
            onChange={(e) => setNewLinkName(e.target.value)}
          />
          <input
            type="text"
            placeholder="URL"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') addLink();
            }}
          />
          <button className='add-link' onClick={addLink}>Add</button>
        </div>
      )}
    </div>
  );
};

export default LinksList;
















// // LinksList.js
// import React, { useState } from 'react';
// import '../components/links.css';
// import './styles/linklist.css'

// const LinksList = ({ links, setLinks }) => {
//   const [newLink, setNewLink] = useState('');
//   const [newLinkName, setNewLinkName] = useState('');
//   const [linkOpen, setLinkOpen] = useState(false)

//   const toggleAddLink = () => {
//     setLinkOpen(!linkOpen);
//   }

//   const addLink = () => {
//     if (newLink.trim()) {
//       setLinks([...links, { name: newLinkName || newLink, url: newLink }]);
//       setNewLink('');
//       setNewLinkName('');
//     }
//   };

//   const deleteLink = (index) => {
//     const updatedLinks = links.filter((_, i) => i !== index);
//     setLinks(updatedLinks);
//   };

//   return (
//     <div className='links card'>
      
//       <div className='d-flex justify-between border-b align-center'>
//         <h4>🔗 Links</h4>
//         <button className='add-link-btn' onClick={toggleAddLink}>
//         {linkOpen ? '-' : '+' }
//         </button>
//       </div>

//         {(links || []).map((link, index) => (
//           <div key={index} className='item d-flex justify-between border-b p-5'>
//             <div className='w-100'>
//             <a href={link.url} target="_blank" rel="noopener noreferrer" className='d-flex align-center col-gap-5'>
//               <img src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=512`} alt="logo image" className="favicon" />
//               {link.name}
//             </a>
//             </div>
//             <button onClick={() => deleteLink(index)}>
//               <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
//             </button>
//           </div>
//         ))}


//     {linkOpen ? 
//     <div className='d-flex flex-col mt-20 align-end'>
//       <input
//         type="text"
//         placeholder="Link Name (optional)"
//         value={newLinkName}
//         onChange={(e) => setNewLinkName(e.target.value)}
//       />
//       <input
//         type="text"
//         placeholder="URL"
//         value={newLink}
//         onChange={(e) => setNewLink(e.target.value)}
//         onKeyDown={(e) => {
//           if (e.key === 'Enter') addLink();
//         }}
//       />
//       <button className='add-link' onClick={addLink}>Add</button>
//     </div>
//     : null}
    
//     </div>
//   );
// };

// export default LinksList;
