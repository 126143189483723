import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import './navbar.css'

const stripePromise = loadStripe('pk_test_uRNBzI5T0fPQP18tnGh34AA9'); // Replace with your public Stripe key
const BACKEND_URL = "https://www.noteworkspace.com/api"
// const BACKEND_URL = "http://localhost:4242"

function Navbar({ toggleSidebar, accessKey, successMessage, setSuccessMessage }) {
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(259200); // 48 hours in seconds
    const [accessToken, setAccessToken] = useState('');
    const [isPrivateMode, setIsPrivateMode] = useState(false); // Track private mode status

        // Function to encode timestamp in Base64
        const encodeBase64 = (data) => btoa(data.toString());

        // Function to decode Base64 to timestamp
        const decodeBase64 = (data) => {
            try {
                return parseInt(atob(data));
            } catch (error) {
                console.error("Failed to decode Base64 string:", error);
                return Date.now(); // Fallback to current timestamp if decoding fails
            }
        };

    // useEffect(() => {
    //     // console.log(accessKey);
    // }, [accessKey]);


    // useEffect(() => {
    //     if (accessKey === 'paid') {
    //         return;
    //     }
    //     // Retrieve NoteWorkspace from localStorage
    //     const noteWorkspaceData = JSON.parse(localStorage.getItem('NoteWorkspace')) || {};

    //     // Initialize countdownStart object if it doesn’t exist
    //     if (!noteWorkspaceData.countdownStart) {
    //         noteWorkspaceData.countdownStart = {
    //             countdown: encodeBase64(Date.now()), // Encode timestamp to Base64
    //             sidebar: "permanent"   // Set sidebar key as "permanent"
    //         };
    //         setTimeLeft(259200); // start a new 48-hour countdown
    //     } else {
    //         // Decode and calculate remaining time if countdown already exists
    //         const countdownStart = noteWorkspaceData.countdownStart.countdown;
    //         const startTime = countdownStart && countdownStart !== '' ? decodeBase64(countdownStart) : Date.now();
    //         const timeElapsed = Math.floor((Date.now() - startTime) / 1000);
    //         const remainingTime = Math.max(259200 - timeElapsed, 0);
    //         setTimeLeft(remainingTime);
    //     }

    //     // Save updated NoteWorkspace back to localStorage
    //     localStorage.setItem('NoteWorkspace', JSON.stringify(noteWorkspaceData));

    //     if (timeLeft > 0) {
    //         const timerId = setInterval(() => {
    //             setTimeLeft((prevTime) => {
    //                 if (prevTime > 1) {
    //                     return prevTime - 1;
    //                 } else {
    //                     clearInterval(timerId); // stop timer when it reaches 0
    //                     alert("⏰ Time's up! Your free trial has ended.");
    //                     localStorage.removeItem('NoteWorkspace'); // Delete local storage
    //                     noteWorkspaceData.countdownStart = {
    //                         countdown: encodeBase64(Date.now()), // Restart countdown with new timestamp
    //                         sidebar: "permanent"
    //                     };
    //                     setTimeLeft(259200); // Restart the 48-hour countdown
    //                     localStorage.setItem('NoteWorkspace', JSON.stringify(noteWorkspaceData)); // Save updated NoteWorkspace
    //                     return 259200; // Restart timer
    //                 }
    //             });
    //         }, 1000);

    //         return () => clearInterval(timerId); // Clear interval on unmount
    //     }
    // }, [timeLeft]);

    // Convert seconds to HH:MM:SS format
    // const formatTime = (seconds) => {
    //     const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    //     const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    //     const secs = String(seconds % 60).padStart(2, '0');
    //     return `${hours}:${minutes}:${secs}`;
    // };



    const handleBuyNow = () => {
        // window.open('https://buy.stripe.com/6oE9Dn1qt2wZ20U4gh', '_blank');
        navigate('/pricing')
    };




    // const handleBuyNow = async () => {
    //     const stripe = await stripePromise;

    //     // Call your backend to create the checkout session
    //     const response = await fetch('http://localhost:4242/create-checkout-session', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     });

    //     const session = await response.json();

    //     // Redirect to Stripe Checkout
    //     const result = await stripe.redirectToCheckout({
    //         sessionId: session.id,
    //     });

    //     if (result.error) {
    //         alert(result.error.message);
    //     }
    // };

    // function downloadLocalStorage() {
    //     const data = JSON.parse(localStorage.getItem('NoteWorkspace')); // Parse the stored JSON string back to an object
    //     const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }); // Now stringify as JSON with spacing
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = 'NoteWorkspace.json';
    //     link.click();
    // }

    // function deleteAllData() {
    //     const firstConfirmation = window.confirm("Are you sure you want to delete all your data? This cannot be recovered.");

    //     if (firstConfirmation) {
    //         const secondConfirmation = window.confirm("This action is permanent. Do you really want to proceed with deleting all your data?");

    //         if (secondConfirmation) {
    //             localStorage.removeItem('NoteWorkspace');
    //             alert("✅ Your data has been successfully deleted");
    //             navigate('/');
    //             window.location.reload();
    //         }
    //     }
    // }

    const handleTokenSubmit = async (e) => {
        e.preventDefault(); // Prevents page refresh

        try {
            const response = await fetch(`${BACKEND_URL}/validate-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: accessToken }),
            });

            const data = await response.json();

            // If Token Matches inside server.js
            if (data.valid) {

                const savedFolders = JSON.parse(localStorage.getItem('NoteWorkspace')) || {};
                savedFolders["accessKey"] = {key: "paid", sidebar: "permanent"}

                // Save updated object back to localStorage
                localStorage.setItem('NoteWorkspace', JSON.stringify(savedFolders));

                setSuccessMessage("✅ Access Granted!"); // Set success message
                alert("✅ Access Granted!");
                navigate('/');
                window.location.reload();
            } else {

                alert("❌ Invalid Access Token");
                setSuccessMessage('❌ Invalid Access Token'); // Clear success message if validation fails

            }
        } catch (error) {
            console.error("Error validating token:", error);
        }
    };



    return (
        <>
            <div className="navbar d-flex justify-between align-center border-b text-left">
                <div className='mobile-tablet-only hamburger-menu' onClick={toggleSidebar}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12" /><line x1="4" x2="20" y1="6" y2="6" /><line x1="4" x2="20" y1="18" y2="18" /></svg>
                </div>

                <a href='/' className="logo-link d-flex flex-col" style={{ marginTop: '-5px' }}>
                    <h3 className="logo">📓 Note Workspace</h3>
                    {/* <div className='logo-subtitle'>Study, take notes, organize your tasks</div> */}
                    <div className='logo-subtitle'>Your Notetaking Dashboard</div>
                </a>

                <div className='mobile-tablet-only hamburger-menu'>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12"/><line x1="4" x2="20" y1="6" y2="6"/><line x1="4" x2="20" y1="18" y2="18"/></svg> */}
                    {/* <button className='buy-now-nav buy-now-nav-mobile' onClick={handleBuyNow}>Buy Now</button> */}
                    <div className='buy-now-nav-mobile' onClick={handleBuyNow}>Pricing</div>
                </div>

                {/* <div className='mobile-tablet-only logo-subtitle'>Better On Desktop</div> */}
                {/* <div className='navbar-buttons d-flex align-center col-gap-10'>
                <div style={{fontSize: "12px", fontFamily: 'Geist Medium', color: "Grey"}}>Coming soon...</div>
                <button>Pomodoro</button>
                <button>Timer</button>
                <button>Stopwatch</button>
                <button>Music</button>
                </div> */}

                {/* {accessKey === 'demo' ? 
                <>
                    <div className='timer-bar-desktop center d-flex align-center col-gap-10'>
                        <div className='d-flex flex-col align-end'>
                            <div className='free-trial d-flex col-gap-5'>Demo: Try Free For 72 hours
                                <div className="clock">{formatTime(timeLeft)}</div>
                            </div>

                            <div className='subtitle-text'>Demo: Data deletes every 72 hours until purchase</div>
                        </div>
                        <div className='free-trial d-flex col-gap-5'>Or</div>
                        <button className='buy-now-nav buy-now-nav-desktop' onClick={handleBuyNow}>Buy Now</button>
                    </div>

                    <div className="timer-bar-desktop access-token d-flex flex-col col-gap-5 align-start">
                        <form onSubmit={handleTokenSubmit} className='d-flex align-center col-gap-5'>
                            <div style={{ whiteSpace: "nowrap" }}>Access Token</div>
                            <input
                                type="text"
                                value={accessToken}
                                onChange={(e) => setAccessToken(e.target.value)}
                            />
                            <button type="submit">Submit</button>
                        </form>
                        {successMessage ? <div className="success-message">{successMessage}</div>
                            : <div className='subtitle-text'>No login required. Receive access token after purchase</div>}

                    </div>
                </>
                : null}

            </div>


                {accessKey === 'demo' ? 
                <>
                    <div className='timer-bar d-flex'>

                        <div className='center d-flex align-center col-gap-10'>
                            <div className='d-flex flex-col align-end'>
                                <div className='free-trial d-flex col-gap-5'>Demo: Try Free for 72 hours
                                    <div className="clock">{formatTime(timeLeft)}</div>
                                </div>

                                <div className='subtitle-text'>Demo Version: Data deletes every 72 hours until purchse</div>
                            </div>
                        </div>

                        <div className="access-token d-flex flex-col col-gap-5 align-start">

                            <div className='d-flex align-center col-gap-5'>
                                <div>Access Token</div>
                                <input />
                            </div>
                            <div className='subtitle-text'>No login required. Receive access token after purchase</div>

                            <div className="nav-buy-now-text" onClick={handleBuyNow}>Buy Now $30</div>

                        </div>

                    </div>
                </>
                : null} */}
            </div>
        </>
    )
}

export default Navbar;