// Page.js
import React, { useState, useEffect, useRef } from 'react';

import ToDoList from './ToDoList';
import LinksList from './LinksList';
import Youtube from './Youtube';
import Notes from './Notes';
import Jodit from './Jodit';

const ChatgptPrompts = ({ chatgptpromptsFolderId, chatgptpromptsPageId, folders, updateFolders, onAddVideo, onDeleteVideo, editorWidth, setEditorWidth, handleMouseDownEditor, currentNote, setCurrentNote, isPageReady, setIsPageReady }) => {



  // const { chatgptpromptsFolderId, chatgptpromptsPageId } = useParams();
  const [notes, setNotes] = useState(folders[chatgptpromptsFolderId].pages[chatgptpromptsPageId].notes || []); // Initializing notes

  const [sectionHeight, setSectionHeight] = useState(500);
  const requestRef = useRef(null);
  const initialMouseY = useRef(null);

  useEffect(() => {
    const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};

    // Check if the notes exist for the specified chatgptpromptsFolderId and chatgptpromptsPageId
    const loadedNotes = workspaceData[chatgptpromptsFolderId]?.pages[chatgptpromptsPageId]?.notes || [];
    setNotes(loadedNotes); // Update notes state
    // setCurrentNote(0);

  }, [chatgptpromptsFolderId, chatgptpromptsPageId, folders]);

  // useEffect(() => {
  //   // Check if the notes exist for the specified chatgptpromptsFolderId and chatgptpromptsPageId in updated folders
  //   const pageNotes = folders[chatgptpromptsFolderId]?.pages[chatgptpromptsPageId]?.notes || [];
  //   setNotes(pageNotes); // Update notes state
  //   setCurrentNote(0);
  // }, [chatgptpromptsFolderId, chatgptpromptsPageId, folders]);



  // Ensure both chatgptpromptsFolderId and chatgptpromptsPageId are valid, then access the data
  if (!folders[chatgptpromptsFolderId] || !folders[chatgptpromptsFolderId].pages[chatgptpromptsPageId]) return <div>Page not found</div>;
  const pageData = folders[chatgptpromptsFolderId].pages[chatgptpromptsPageId];
  const page = folders[chatgptpromptsFolderId]?.pages[chatgptpromptsPageId] || { todoList: [], linkList: [], notes: [] };

  // const updateTodoList = (newTodos) => {
  //   const updatedFolders = {
  //     ...folders,
  //     [chatgptpromptsFolderId]: {
  //       ...folders[chatgptpromptsFolderId],
  //       pages: {
  //         ...folders[chatgptpromptsFolderId].pages,
  //         [chatgptpromptsPageId]: { ...page, todoList: newTodos },
  //       },
  //     },
  //   };
  //   updateFolders(updatedFolders);
  // };


  const updateLinkList = (newLinks) => {
    const updatedFolders = {
      ...folders,
      [chatgptpromptsFolderId]: {
        ...folders[chatgptpromptsFolderId],
        pages: {
          ...folders[chatgptpromptsFolderId].pages,
          [chatgptpromptsPageId]: { ...page, linkList: newLinks },
        },
      },
    };
    updateFolders(updatedFolders);
  };

  const updateNotes = (newNotes) => {
    const updatedFolders = {
      ...folders,
      [chatgptpromptsFolderId]: {
        ...folders[chatgptpromptsFolderId],
        pages: {
          ...folders[chatgptpromptsFolderId].pages,
          [chatgptpromptsPageId]: { ...page, notes: newNotes },
        },
      },
    };
    updateFolders(updatedFolders);
  };



  const addNote = () => {
    // Generate the next note title based on the number of existing notes
    const newNoteTitle = `Note ${notes.length + 1}`;
    const newNote = { title: newNoteTitle, content: `<h1>Title: ${newNoteTitle}</h1><h2>Subtitle</h2><hr/><p>Text</p> <p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p><p></p>` };

    // Update the notes array with the new note
    const updatedNotes = [...notes, newNote];
    setNotes(updatedNotes); // Update state

    // Set the current note index to the newly added note
    setCurrentNote(notes.length);

    // Save updated notes to local storage
    updateNotes(updatedNotes);

    // Save the updated folders to localStorage
    const updatedFolders = {
      ...folders,
      [chatgptpromptsFolderId]: {
        ...folders[chatgptpromptsFolderId],
        pages: {
          ...folders[chatgptpromptsFolderId].pages,
          [chatgptpromptsPageId]: { ...page, notes: updatedNotes }
        }
      }
    };
    localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
    updateFolders(updatedFolders); // Ensure parent component updates with new structure
  };



  const selectNote = (index) => {
    const workspaceData = JSON.parse(localStorage.getItem("NoteWorkspace")) || {};

    // Retrieve the latest note content from localStorage
    if (
      workspaceData[chatgptpromptsFolderId] &&
      workspaceData[chatgptpromptsFolderId].pages &&
      workspaceData[chatgptpromptsFolderId].pages[chatgptpromptsPageId] &&
      workspaceData[chatgptpromptsFolderId].pages[chatgptpromptsPageId].notes &&
      workspaceData[chatgptpromptsFolderId].pages[chatgptpromptsPageId].notes[index]
    ) {
      const latestNotes = workspaceData[chatgptpromptsFolderId].pages[chatgptpromptsPageId].notes;

      // Update `notes` state to sync with localStorage
      setNotes(latestNotes);
    }

    setCurrentNote(index);
  };

  const deleteNote = (index) => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      // Filter out the deleted note from the notes array
      const updatedNotes = notes.filter((_, i) => i !== index);
      setNotes(updatedNotes); // Update state
      setCurrentNote(0); // Reset currentNote when a note is deleted

      // Update notes in local storage and in the parent folder structure
      updateNotes(updatedNotes);

      // Save the updated folders to localStorage
      const updatedFolders = {
        ...folders,
        [chatgptpromptsFolderId]: {
          ...folders[chatgptpromptsFolderId],
          pages: {
            ...folders[chatgptpromptsFolderId].pages,
            [chatgptpromptsPageId]: { ...page, notes: updatedNotes }
          }
        }
      };
      localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
      updateFolders(updatedFolders); // Ensure parent component updates with new structure
    }
  };

  const renameNote = (index, newTitle) => {
    const updatedNotes = notes.map((note, i) =>
      i === index ? { ...note, title: newTitle } : note
    );
    setNotes(updatedNotes);
    updateNotes(updatedNotes);

    // Update localStorage
    const updatedFolders = {
      ...folders,
      [chatgptpromptsFolderId]: {
        ...folders[chatgptpromptsFolderId],
        pages: {
          ...folders[chatgptpromptsFolderId].pages,
          [chatgptpromptsPageId]: { ...page, notes: updatedNotes }
        }
      }
    };
    localStorage.setItem("NoteWorkspace", JSON.stringify(updatedFolders));
    updateFolders(updatedFolders);
  };

  const handleMouseMove = (e) => {
    if (requestRef.current || initialMouseY.current === null) return;
    requestRef.current = requestAnimationFrame(() => {
      const newHeight = sectionHeight + (e.clientY - initialMouseY.current);
      setSectionHeight(Math.max(19, Math.min(newHeight, 800)));
      requestRef.current = null;
    });
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    initialMouseY.current = null;
  };

  const handleMouseDown = (e) => {
    initialMouseY.current = e.clientY;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };


  return (
    <div>


      <div className='content-inner d-flex col-gap-1rem'>
        <div className='first-section d-flex flex-col'>



          <div className='first-container d-flex col-gap-1rem relative z-1' style={{ height: `${sectionHeight}px` }}>
            <div className='todo-border'></div>
            <div className="section-resize" onMouseDown={handleMouseDown}>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-grip-vertical"><circle cx="9" cy="12" r="1" /><circle cx="9" cy="5" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="19" r="1" /></svg>
            </div>

            <div className='d-flex flex-col w-100'>
              <div>
                <div className='d-flex align-center col-gap-15 justify-start'>
                  <h2 className='page-title'>💬 ChatGPT Prompts</h2>
                  {/* <h5 className='page-subtitle' style={{ color: "#666", margin: "0px" }}>📁 {folders[chatgptpromptsFolderId].name}</h5> */}
                </div>
              </div>

              <div className='first-container-inner d-flex col-gap-1rem'>
                <div className='w-60'>
                  <ToDoList
                    todos={pageData.todoList}
                    setTodos={(newTodos) => {
                      const updatedFolders = {
                        ...folders,
                        [chatgptpromptsFolderId]: {
                          ...folders[chatgptpromptsFolderId],
                          pages: {
                            ...folders[chatgptpromptsFolderId].pages,
                            [chatgptpromptsPageId]: { ...page, todoList: newTodos },
                          },
                        },
                      };
                      updateFolders(updatedFolders);
                    }}
                    folderId={chatgptpromptsFolderId}
                    pageId={chatgptpromptsPageId}
                    updateFolders={updateFolders}
                  />

                </div>

                <div className='w-40 d-flex flex-col row-gap-1rem'>

                  <Notes
                    folders={folders}
                    notes={notes}
                    setNotes={setNotes}
                    addNote={addNote}
                    selectNote={selectNote}
                    renameNote={renameNote}
                    deleteNote={deleteNote}

                    folderId={chatgptpromptsFolderId}
                    pageId={chatgptpromptsPageId}
                    updateFolders={updateFolders}
                  />

                  <LinksList
                    links={page.linkList}
                    setLinks={updateLinkList}
                    folderId={chatgptpromptsFolderId}
                    pageId={chatgptpromptsPageId}
                  />
                </div>
              </div>
            </div>
          </div>

          <Youtube
            videoIds={folders[chatgptpromptsFolderId]?.pages[chatgptpromptsPageId]?.videoList || []}
            onAddVideo={onAddVideo}
            onDeleteVideo={onDeleteVideo}
            folderId={chatgptpromptsFolderId}
            pageId={chatgptpromptsPageId}       
          />

        </div>

        {/* <div className="w-50"> */}

        <Jodit
          notes={page.notes}
          currentNote={currentNote}
          // setNotes={setNotes}
          addNote={addNote}
          selectNote={selectNote}
          deleteNote={deleteNote}

          setNotes={updateNotes}
          folderId={chatgptpromptsFolderId}
          pageId={chatgptpromptsPageId}

          editorWidth={editorWidth}
          setEditorWidth={setEditorWidth}
          handleMouseDownEditor={handleMouseDownEditor}
          isPageReady={isPageReady}
          setIsPageReady={setIsPageReady}
        />

        {/* </div> */}

      </div>
    </div>
  );
};

export default ChatgptPrompts;
