import React, { useState, useEffect } from 'react';
import AddAVideo from './AddAVideo'
import './styles/youtube.css'

function Youtube({videoIds, onAddVideo, onDeleteVideo, folderId, pageId}) {
    // const { folderId, pageId } = useParams();
    const [openWindow, setOpenWindow] = useState(false);
    // const videoId = "O3_qDL4Ls_s"

    const toggleWindowOpen = () => {
        setOpenWindow(!openWindow);
    }

    useEffect(() => {

      }, [videoIds]);

    return (
        <div className={`${openWindow ? 'youtube youtube-open-window' : 'youtube'}`}>

            <div className='expand-window' onClick={toggleWindowOpen}>
                {openWindow ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-minimize-2"><polyline points="4 14 10 14 10 20"/><polyline points="20 10 14 10 14 4"/><line x1="14" x2="21" y1="10" y2="3"/><line x1="3" x2="10" y1="21" y2="14"/></svg>
                :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-maximize-2"><polyline points="15 3 21 3 21 9"/><polyline points="9 21 3 21 3 15"/><line x1="21" x2="14" y1="3" y2="10"/><line x1="3" x2="10" y1="21" y2="14"/></svg>
                }
            </div>
            
            <div className='youtube-inner'>
            <AddAVideo onAddVideo={onAddVideo} folderId={folderId} pageId={pageId} />

            {videoIds.map(videoId => (
            <div className='card' key={videoId}>
                <button onClick={() => onDeleteVideo(folderId, pageId, videoId)} className="delete-button">Remove</button>

                <div className='auto-resizable-iframe resize mb-20'>
                    <div className='iframe-div'>
                        <iframe
                            // ref={iframeRef}
                            width="560"
                            height="315"
                            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
                                <a href=https://www.youtube.com/embed/${videoId}?enablejsapi=1>
                                    <img src=https://img.youtube.com/vi/${videoId}/hqdefault.jpg alt='Generic alt' style="width:102%">
                                    <span style='font-size:45px;text-shadow: 1px 1px 0 rgba(0,0,0,1), 1px 1px 5px rgba(0,0,0,1)'>
                                    ▸
                                    </span>
                                </a>`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                </div>

                <a className='video-link' href={`https://www.youtube.com/watch?v=${videoId}`} target="_blank" rel="noreferrer">
                {`https://www.youtube.com/watch?v=${videoId}`}
                <div className='d-flex align-center'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-external-link"><path d="M15 3h6v6"/><path d="M10 14 21 3"/><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"/></svg></div>
                </a>
            </div>
            ))}
            </div>
        </div>
    )
}

export default Youtube;